import React, { Component } from "react";
import "./pearl.css";
import Nav from "./Nav";
import Footer from "./Footer";
import "./circleisland.css";
import tigerLady from '../img/tiger-lady.jpeg';
import totoro from '../img/totoro.jpeg';

export default class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "$395",
      show: "hideValue"
    };
    this.showPrice = this.showPrice.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/285354/?fallback=simple&flow=14429"
    script.async = true;

    document.body.appendChild(script);
  }

  showPrice() {
    const e = document.getElementById("prices");
    let value = e.options[e.selectedIndex].value;
    this.setState({
      value: value
    });

    this.setState({
      show: "showValue"
    });
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="container">
          <div className="frame-intro-tourDesc">
            <div className="halfFrameTwotourDesc" id="Culture"></div>
            <div className="flex-container">
              <div className="tourDesc">
                <h1>Culture, Food & Art Tour</h1>
                <p>
                  <i>Chinatown and Kaka'ako</i>
                </p>
                <br />
                <h2>Duration: About 5 hours</h2>
                <br />
                <h3>Cost from $395</h3>
                <br />
                <h3>
                  Start Time: Whenever you choose between 8:45 and 11:00 AM
                </h3>

                <br />

                <br />
                <div className={this.state.show}>
                  <h3>Price: {this.state.value}</h3>
                </div>
                <div className="visit-desc">
                  <h1>Details</h1>
                  <br />
                  <p>
                    Explore one of the nation’s oldest Chinatowns. Enjoy bits of
                    local history and dim sum favorites along with “crack seed”
                    specialties. About two miles east, the Kaka’ako neighborhood
                    continues to morph from industrial warehouses to a vibrant
                    hub of condos and cafes. Take in the creative murals by
                    Pow!Wow! Festival artists from around the world. Sample poke
                    and traditional Hawaiian fare. Polish off your culinary
                    adventure with a coffee or tea. Transportation provided from
                    Waikiki hotels. Ko’olina pick-up available. Moderate
                    walking. If anyone in your party uses a walker or wheelchair, please call ahead before booking. Many food items we will sample contain pork or fish. Please keep
                    this in mind if anyone in your party has dietary
                    restrictions.
                  </p>
                  <br></br>
                  <p>
                    <strong>Tour Includes:</strong>
                  </p>
                  <ul className='visit-list'>
                    <li>
                      Private transportation from your Waikiki-area hotel,
                      cruise ship or airport*
                    </li>
                    <li>Bottled water and all food at tour locations</li>
                    <li>
                      Chinatown marketplace, dim sum factory, bakery, “crack
                      seed” store
                    </li>
                    <li>
                      Pow!Wow! street art murals, poke, traditional Hawaiian
                      fare
                    </li>
                    <br/>
                    <i>*Surcharge for cruise terminal and airport pickups</i>
                  </ul>
                </div>
                <br />
              </div>
              <div className='culture-tour-images'>
              <img src={tigerLady} alt='' height='300px' id='NSimage'></img>
              <img src={totoro} alt='' height='300px' id='NSimage'></img>

              </div>
              <div className="iframe">
                <iframe
                  title="north-iframe"
                  id="1582150095035-541231278626609"
                  name="north-iframe"
                  frameBorder="0"
                  border="0"
                  width="100%"
                  height="427px"
                  src="https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/285354/2021/04/?fallback=simple&flow=14429"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
