import React, { Component } from 'react';
import './footer.css';

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className='footer'>
        <p>&copy; 2020 Donna's Detours</p>
        </footer>
      </div>
    )
  }
}
