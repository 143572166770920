import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="frame-intro">
            <div className="halfFrameTwo"></div>
            <div className="halfFrameTwoDesc">
            
              <h1>Circle Island Tours</h1>
              <br />
              <p>
                Sample the Windward Coast and North Shore’s natural beauty and
                local foods. Choose the introductory tour or customize your
                experience.
              </p>
              <br />
              <Link to="/oahucircleislandtour">
                <button className="bookbtn">Full Info & Pricing</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
