import React, { Component } from "react";
import "./pearl.css";
import Nav from "./Nav";
import Footer from "./Footer";
import "./circleisland.css";
import "./home.css";
import east from '../img/east.jpg';
import east2 from '../img/east2.jpg';
import east3 from '../img/east3.JPG';


export default class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "$425",
      show: "hideValue"
    };
    this.showPrice = this.showPrice.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://fareharbor.com/embeds/book/privatetoursofoahu/items/35197/?full-items=yes&flow=14429";
    script.async = true;

    document.body.appendChild(script);
  }

  showPrice() {
    const e = document.getElementById("prices");
    let value = e.options[e.selectedIndex].value;
    this.setState({
      value: value
    });

    this.setState({
      show: "showValue"
    });
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="container">
          <div className=".frame-intro-tourDesc">
            <div className="halfFrameTwotourDesc" id="circle"></div>
            <div className="flex-container">
              <div className="tourDesc">
                <h1>Circle Island Tour</h1>
                <br />
                <h2>Duration: About 8 hours</h2>
                <br />
                <h3>Cost from $425</h3>
                <br />
                <div className={this.state.show}>
                  <h3>Price: {this.state.value}</h3>
                </div>
                <div className="visit-desc">
                  <h1>Details</h1>
                  <br />
                  <h4>
                    First time on Oahu? Visit these scenic and historic sites on
                    a full-day discovery tour.
                  </h4>
                  <ul className="visit-list">
                    <li>
                      Honolulu historic sites – Iolani Palace, King Kamehameha
                      the Great Statue, Mission Houses, Old Kawaiaha’o Church.
                      Drive by or get out and walk around.
                    </li>
                    <li>Rain forest drive along Nu’uanu Stream</li>
                    <li>Pali Lookout </li>
                    <li>Tropical Farms Macadamia Nut and Kona Coffee sampling</li>
                    <li>Lunch* at a Kahuku shrimp truck, “plate lunch” spot or more upscale restaurant</li>             
                    <li>
                      Secluded bay for a swim, or relax in the shade of Ironwood pine and coconut trees Famous North Shore surfing beaches
                    </li>
                    <li>Sharks Cove</li>
                    <li>
                      Historic Haleiwa Town –plantation-era buildings with shops
                      and shave ice{" "}
                    </li>
                    <li>Dole Plantation</li>
                    <li>Bottled water provided</li>
                    <br></br>
                        <i>*Lunch not included in price</i>
               
                    
                  </ul>
                  <br></br>
                  <h2>Enhance your experience by adding:</h2>
                  <br />
                  <h4>Waimea Valley & Falls ($30 per person)</h4>
                  <p> Admission and one-way shuttle</p>
                  <br />
                  <h4>Byodo In ($5 per person)</h4>
                  <p> Re-creation of an ancient Japanese Buddhist temple</p>
                </div>
                <img src={east} alt='' height='300px' id='NSimage'></img>
                <img src={east2} alt='' height='300px' id='NSimage'></img>
                <img src={east3} alt='' height='300px' id='NSimage'></img>

                <br />
              </div>
              <div className="iframe">
                <iframe
                  title="north-iframe"
                  id="1582150095035-541231278626609"
                  name="north-iframe"
                  frameBorder="0"
                  border="0"
                  width="100%"
                  height="427px"
                  src="https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/35174/2020/02/"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
