import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./home.css";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="frame-intro">
            <div className="halfDay" id="North"></div>
            <div className="halfFrameTwoDesc">
              <h1>North Shore Tour</h1>
              <br />
              <p>
                Trade Waikiki’s hectic pace for the lush landscapes, surf
                beaches and tropical fruit stands of Oahu’s North Shore.
              </p>
              <br />
              <Link to="/northshoretour">
                <button className="bookbtn">Full Info & Pricing</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
