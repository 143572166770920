import React, { Component } from "react";
import "./pearl.css";
import Nav from "./Nav";
import Footer from "./Footer";
import gun from '../img/gun.JPG';
import arizona from '../img/arizona.JPG';

export default class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "$396",
      show: "hideValue"
    };
    this.showPrice = this.showPrice.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://fareharbor.com/embeds/book/privatetoursofoahu/items/35197/?full-items=yes&flow=14429";
    script.async = true;

    document.body.appendChild(script);
  }

  showPrice() {
    const e = document.getElementById("prices");
    let value = e.options[e.selectedIndex].value;
    this.setState({
      value: value
    });

    this.setState({
      show: "showValue"
    });
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="container">
          <div className=".frame-intro-tourDesc">
            <div className="halfFrameTwotourDesc" id="Pearl"></div>
            <div className="flex-container">
              <div className="tourDesc">
                <h1>Pearl Harbor Highlights & Historic Honolulu Sites</h1>
                <br />
                <h2>Duration: About 4 hours</h2>

                <br />
                <h3>Cost from $396</h3>
                <br />
                <div className="visit-desc">
                  <h1>Details</h1>
                  <br />
                  <h4>
                    Tour the site of Japan's attack that drew America into WWII
                    and visit downtown Honolulu's historic sites. Your guide
                    will escort you around the Visitors Centers exhibits.
                  </h4>
                  <br />

                  <h4>Tour Includes:</h4>

                  <ul className="visit-list">
                    <li>
                      Private transportation from your Waikiki-area hotel,
                      cruise ship or airport*
                    </li>
                    <li>Guide services throughout the day</li>
                    <li>
                      Advance-reservation tickets to the USS Arizona Memorial
                    </li>
                    <li>Documentary film about the attack</li>
                    <li>
                      Visitors Center exhibits, including FDR’s speech with his
                      hand-written edits
                    </li>
                    <li>Bottled water and light snacks</li>
                    <li>
                      Drive by or get out (if time permits) and walk around
                      Iolani Palace, King Kamehameha I statue, Old Kawaiaha'o
                      Church and Mission Houses
                    </li>
                  </ul>
                  <br />
                  <p>*Surcharge for cruise terminal and airport pickups</p>
                  <br />
                  <h2>
                    Complete your Pearl Harbor experience - add one or more
                    attraction:
                  </h2>
                  <br />
                  <h4>USS Bowfin Submarine ($16 per person)</h4>
                  <p>
                    {" "}
                    Add 45 minutes. VIP Captain’s Tour available by special
                    request; email or call for availability.
                  </p>
                  <br />
                  <h4>Battleship Missouri ($32 per person)</h4>
                  <p>
                    {" "}
                    Add 2 hours and $60. Stand on the deck where Emperor Hirohito’s
                    representatives surrendered to Gen. MacArthur and allied
                    forces.
                  </p>
                  <br />
                  <h4>Pearl Harbor Aviation Museum ($25 per person)</h4>
                  <p>
                    {" "}
                    Add 2 hours and $60. Restored aircraft from WWII to the end of the
                    20th Century, including a Japanese “Zero,” Soviet MiG and
                    George H.W. Bush’s trainer. Test your pilot skills in the
                    new flight simulator.
                  </p>
                  <br />
                  <p>
                    <i>
                      Note: Please advise if anyone in your party is active
                      military or Department of Defense, with proper ID, for
                      possible discounts.
                    </i>
                  </p>
                  <img src={gun} alt='' height='300px' id='NSimage'></img>
                  <img src={arizona} alt='' height='300px' id='NSimage'></img>
                </div>
                <br />
              </div>
              <div className="iframe">
                <iframe
                  title="north-iframe"
                  id="1582150095035-541231278626608"
                  name="north-iframe"
                  frameBorder="0"
                  border="0"
                  width="100%"
                  height="427px"
                  src="https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/35197/2020/02/"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
