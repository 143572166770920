import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./about.css";
import Nav from "./Nav";
import Footer from "./Footer";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <Nav />
        <div className="about-container">
          <div className="frame-intro">
            <div className="halfFrameTwoAbout" id="about"></div>
            <div className="halfFrameTwoDesc">
              <h1>About</h1>
              <br />
              <p>
                After many years in the corporate world, I stepped off the
                career ladder and into a tour van. A never-ending sense of
                adventure has led me to discover many lesser known spots of
                scenic beauty, a deeper appreciation of Hawaiian cultural sites
                and those of American military significance. I look forward to
                showing you the best of Oahu’s scenic trails, historic sites,
                cultural activities and local delicacies on a private tour,
                customized for you. For your comfort and safety, we take only
                one tour per day so we can sanitize the interior and all touch
                surfaces of the Donna’s Detour-mobile after each trip. We
                provide hand sanitizer, and your guide will wear a mask or face
                shield. Masks for you are optional except when entering cafes or
                shops that require them. You’ll find a collection of
                Hawaiian-print masks for purchase onboard in case yours gets
                left behind or you just want another memento of your trip!
              </p>
              <br/>
              <strong>PUC License #537-C</strong>
              <br />
              <br/>
              <Link to="/">
                <button className="bookbtn">Back to Tours</button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <Footer />
        </div>
      </div>
    );
  }
}
