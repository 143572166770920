import React, { Component } from "react";
import "./pearl.css";
import Nav from "./Nav";
import Footer from "./Footer";
import "./circleisland.css";

export default class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "$396",
      show: "hideValue"
    };
    this.showPrice = this.showPrice.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://fareharbor.com/embeds/book/privatetoursofoahu/items/35197/?full-items=yes&flow=14429";
    script.async = true;
  }

  showPrice() {
    const e = document.getElementById("prices");
    let value = e.options[e.selectedIndex].value;
    this.setState({
      value: value
    });

    this.setState({
      show: "showValue"
    });
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="container">
          <div className=".frame-intro-tourDesc">
            <div className="halfFrameTwotourDesc" id="East"></div>
            <div className="flex-container">
              <div className="tourDesc">
                <h1>East Oahu Beaches & Honolulu Historic Sites</h1>
                <br />
                <h2>Duration: About 4 hours</h2>
                <br />
                <h3>Cost from $325</h3>
                <br/>
                <h3>
                  Start Time: Whenever you choose between 8:45 and 1:00 PM
                </h3>
                <br />
                <p>
                  <i>Cruise pier pick-up: + $20</i>
                </p>
                <p>
                  <i>Airport pick-up: + $25</i>
                </p>
                <p>
                  <i>Ko'olina/Aulani Resort: + $100</i>
                </p>
                <br />
                <div className="visit-desc">
                  <h1>Details</h1>
                  <br />
                  <p>
                    <ul className='visit-list'>
                      <li>Waikiki Beach</li>
                      <li>Diamond Head Lookout</li>
                      <li>Leonard's Famous Malasadas (Portuguese donuts)</li>
                      <li>Koko Head Lookout</li>
                      <li>Hanauma Bay Overlook</li>
                      <li>
                        Halona Blow Hole and "From Here to Eternity" Beach
                      </li>
                      <li>Makapu'u Lookout and optional Lighthouse hike</li>
                      <li>Waimanalo for shave ice or lunch</li>
                      <li>Award winning beaches</li>
                      <li>Pali Lookout</li>
                      <li>Drive through rain forest</li>
                      <li>
                        Iolani Palace, King Kamehameha Statue, Old Mission
                        Houses
                      </li>
                    </ul>
                    <br/>
                    <i>
                      *Includes transportation from Waikiki, guide services,
                      bottled water, light snacks. Pick-up from cruise pier,
                      airport or Ko’olina available.
                    </i>
                  </p>
                  
                </div>
                <br />
              </div>
              <div className="iframe">
                <iframe
                  title="north-iframe"
                  id="1582150095035-541231278626609"
                  name="north-iframe"
                  frameBorder="0"
                  border="0"
                  width="100%"
                  height="427px"
                  src="https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/215981/2020/02/"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
