import React, { Component } from "react";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="halfFrame-intro">
            <div className="halfFrame-intro aloha">
              <h1>Aloha!</h1>
            </div>
            <p>
              Aloha! Looking to find those off the beaten path spots on Oahu?
              Donna’s Detours offers guided customized tours for just you and
              your family or friends so you can explore the island like a
              native. No sharing a van or waiting for others, these tours are
              created just for you. Whether you’re looking to experience
              Hawai’i’s unique culture, beautiful scenery or local foods, we’ve
              got you covered! Explore some of our offerings below.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
