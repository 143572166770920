import React, { Component } from "react";
import { Route, HashRouter, Switch } from "react-router-dom";
import Home from "./Home";
import CircleIsland from './CircleIsland';
import NorthShoreDesc from './NorthShoreDesc';
import PearlHarbor from './PearlHarbor';
import CultureTour from './CultureTour';
import Eastside from './Eastside';
import About from './About';
import Contact from './Contact';


export default class Routes extends Component {
  

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={routerProps => <Home {...routerProps} />}
          />

          <Route
            exact
            path="/about"
            render={routerProps => <About {...routerProps} />}
          />

          <Route
          exact
          path="/pearlharborprivatetour"
          render={routerProps => <PearlHarbor {...routerProps} />}
        />

        <Route
        exact
        path="/oahucircleislandtour"
        render={routerProps => <CircleIsland {...routerProps} />}
      />


      <Route
      exact
      path="/honolulufood-and-culturetour"
      render={routerProps => <CultureTour {...routerProps} />}
    />

      <Route
      exact
      path="/northshoretour"
      render={routerProps => <NorthShoreDesc {...routerProps} />}
    />

    <Route
      exact
      path="/oahueastsidetour"
      render={routerProps => <Eastside {...routerProps} />}
    />

      <Route
      exact
      path="/contact"
      render={routerProps => <Contact {...routerProps} />}
    />
        </Switch>
      </HashRouter>
    );
  }
}
