import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="frame-intro">
            <div className="halfFrameTwoDesc">
              <h1>Culture, Food & Art 
</h1>
              <br />
              <p>
                Explore one of the nation’s oldest Chinatowns. Enjoy bits of
                local history and dim sum favorites along with “crack seed”
                specialties. About two miles east, the Kaka’ako neighborhood
                continues to morph from industrial warehouses to a vibrant hub
                of condos and cafes. Take in the creative murals by Pow!Wow!
                Festival artists from around the world. Sample poke and
                traditional Hawaiian fare.
              </p>
              <br />
              <Link to="/honolulufood-and-culturetour">
                <button className="bookbtn">Full Info & Pricing</button>
              </Link>
            </div>
            <div className="halfFrameTwo" id="Culture"></div>
          </div>
        </div>
      </div>
    );
  }
}
