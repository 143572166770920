import React, { Component } from "react";
import "./pearl.css";
import Nav from "./Nav";
import Footer from "./Footer";
import "./circleisland.css";
import waimea from '../img/waimea.JPG';
import turtle from '../img/turtle.JPG';


export default class Frame extends Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://fareharbor.com/embeds/book/privatetoursofoahu/items/35197/?full-items=yes&flow=14429";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="container">
          <div className=".frame-intro-tourDesc">
            <div className="halfFrameTwotourDesc" id="North"></div>
            <div className="flex-container">
              <div className="tourDesc">
                <h1>North Shore Tour</h1>
                <br />
                <h2>Duration: About 6 hours</h2>
                <br />
                <h3>Cost from $425</h3>
                <br />
                <div className="visit-desc">
                  <h1>Details</h1>
                  <br />

                  <p>
                    Visit some of Hawaii’s most famous surf beaches. Sample
                    fresh pineapple and island-grown coffee and macadamia nuts.
                    Eat lunch at a Kahuku shrimp truck or enjoy a cocktail at a
                    beachside café. Our itinerary is flexible – if you prefer
                    not to stop at any of the places below, we won’t! After all,
                    it’s your private tour.
                  </p>
                  <br />
                  <p>
                    <strong>Tour Includes</strong>
                  </p>
                  <ul className="visit-list">
                    <li>Sunset Beach, Pipeline, Waimea Bay, Sharks Cove</li>
                    <li>
                      Look for endangered green sea turtles on a secluded beach
                    </li>
                    <li>Ancient Hawaiian temple grounds</li>
                    <li>
                      Several spots to try locally-grown coffee, chocolate and
                      macadamia nuts
                    </li>
                    <li>Dole Plantation</li>
                    <li>Historic Haleiwa Town</li>
                    <li>
                      Lunch* at a Kahuku shrimp truck, “locals” spot or more
                      upscale café
                    </li>
                    <li>
                      Stroll through Waimea Valley’s lush botanical gardens to a
                      waterfall (optional)
                    </li>
                    <li>
                      Waialua Sugar Mill for shave ice* topped with local fruit
                      syrups
                    </li>
                    <li>Bottled water and light snacks</li>
                  </ul>
                  <br></br>
                  <p>
                    <i>*Not included in price</i>
                  </p>
                  <br />
                  <br />
                </div>
                <br />
                <h2>Enhance your experience by adding:</h2>
                <br />
                <h4>Waimea Valley & Falls ($30 per person)</h4>
                <p> Admission and one-way shuttle</p>
                <br />
                <h4>Beach-side horseback trail ride (from $110 per person)</h4>
                <br />
                <h4>Shark Cage Dive ($100/adult; $70/child under 12)</h4>
                <img src={waimea} alt='' height='300px' id='NSimage'></img>
                <img src={turtle} alt='' height='300px' id='NSimage'></img>
              </div>
             
              <div>
                <div className="iframe">
                  <iframe
                    title="north-iframe"
                    id="1582150095035-541231278626608"
                    name="north-iframe"
                    frameBorder="0"
                    border="0"
                    width="100%"
                    height="427px"
                    src="https://fareharbor.com/embeds/calendar/privatetoursofoahu/items/231809/?fallback=simple&full-items=yes&flow=14429">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
