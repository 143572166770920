import React from "react";
import "./App.css";
import Router from "./components/Router";
import Scroll from "./components/Scroll";

function App() {
  return (
    <div className="App">
      <Router>
        <Scroll />
      </Router>
    </div>
  );
}

export default App;
