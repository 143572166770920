import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./home.css";

export default class Frame extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="frame-intro">
            <div className="halfDay" id="East"></div>
            <div className="halfFrameTwoDesc">
              <h1>East Oahu Beaches and Historic Honolulu</h1>
              <br />
              <p>
                Visit some of the most popular destinations for tourists and
                locals alike. You'll enjoy scenic views of Oahu's east and south sides while taking a dive into the island's deep history. Sites include Diamond Head Lookout, Hanauma Bay Overlook, Makapu'u Lighthouse, Iolani Palace, and delicious treats from Leonard's Bakery.
              </p>
              <br />
              <Link to="/oahueastsidetour">
                <button className="bookbtn">Full Info & Pricing</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
