import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./nav.css";
import logo from "../img/ddlogo.png";
import bars from "../img/bars-solid.svg";
import phone from "../img/phone-call.png";

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNav: false
    };
    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
    this.state.displayNav
      ? this.setState({ displayNav: false })
      : this.setState({ displayNav: true });
  }

  render() {
    const showNav = this.state.displayNav ? "show" : "hide";

    return (
      <div className="nav-container">
        <div>
          <div className="topNav">
          <a href='tel:1-808-469-6050' className='tel-link'><img className="phone" src={phone} alt='phone'></img>1-808-469-6050</a>
          </div>
          <div id="Nav">
            <div className="mobile-wide-container">
              <div className="mobile-wide">
                <div className="branding">
                  <Link to="/">
                    <img src={logo} alt="logo"></img>
                  </Link>
                </div>
                <div className="burger" onClick={this.toggleNav}>
                  <img src={bars} alt="burger" />
                </div>
              </div>
            </div>
            <div className="links">
              <ul>
                <li>
                  <Link className="link" to="/">
                    Tours
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className={`${showNav}`}>
              <div className="x">
                <h1 onClick={this.toggleNav}>X</h1>
              </div>
              <div className="res-nav-items">
                <ul>
                  <li>
                    <Link to="/">Tours</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
