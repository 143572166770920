import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./contact.css";
import Nav from "./Nav";
import Footer from "./Footer";

export default class Frame extends Component {
  


  render() {
    return (
      <div>
        <div ref={this.myRef}></div>
        <Nav />
        <div className="container">
          <div className="frame-intro">
            <div className="halfFrameTwoAbout" id="contact"></div>
            <div className="halfFrameTwoDesc">
              <h1>Contact</h1>
              <br />
              <p>For more information...</p>
              <br />
              <h4>Call: 1-808-469-6050</h4>
              <h4>Email:donna@privatetoursofoahu.com </h4>
              <h4>Mahalo.</h4>
              <br />
              <Link to="/">
                <button className="bookbtn">Back to Tours</button>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
