import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Frame extends Component {
 


  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="frame-intro">
            <div className="halfFrameTwoDesc">
              <h1>Pearl Harbor Highlights & Historic Honolulu Sites</h1>
              <br />
              <p>
                Tour the site of Japan’s attack on America’s Pacific Fleet
                December 7, 1941, proclaimed “A date which will live in infamy,”
                by President Franklin D. Roosevelt. Your guide will greet you at
                your hotel at your desired start time – no need to wake up
                before the sun. Historic photos, military artifacts and survivor
                videos at Pearl Harbor Visitors Center bring to life the events
                of December 7.
              </p>
              <br />
              <Link to="/pearlharborprivatetour">
                <button className="bookbtn">Full Info & Pricing</button>
              </Link>
            </div>
            <div className="halfFrameTwo" id="Pearl"></div>
          </div>
        </div>
      </div>
    );
  }
}
