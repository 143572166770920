import React, { Component } from "react";
import "./home.css";
import Frame from "./Frame";
import Nav from "./Nav";
import SplitFrame from "./Northshore";
import SplitFrameRev from "./SplitFrameRev";
import SplitFrameTwo from "./SplitFrameTwo";
import SplitFrameRevTwo from "./SplitFrameTwoRev";
import SplitFrameThree from "./SplitFrameThree";
import Footer from "./Footer";

export default class Home extends Component {
  componentDidMount() {
    this.setState({
      showCalendar: true
    });
    const script = document.createElement("script");

    script.src = "https://fareharbor.com/embeds/api/v1/?autolightframe=yes";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div id="page-container">
       
          <Nav />
          <div className='content-wrap'>
          <div className="showcase">
            <div className="showcaseTitle">
              <h1>Private Tours of Oahu</h1>
              <a href='https://fareharbor.com/embeds/book/privatetoursofoahu/?full-items=yes"'>
                <button className="btn" id="BookNow">
                  Book Now
                </button>
      
              </a>
              <p className="quote">
              "We were absolutely thrilled with the service. Our tour was
              thorough and personal. Donna made a fabulous trip all the more
              fabulous!" -- Nicole L., Australia
              </p>
            </div>
          </div>
          <Frame />
          <SplitFrame />
          <SplitFrameRev />
          <SplitFrameTwo />
          <SplitFrameRevTwo />
          <SplitFrameThree />
        </div>
        <Footer />
      </div>
    );
  }
}
